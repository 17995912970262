import { PORTAL_URL, API_URL } from "../../constants";
import { useMemo, useState, useEffect } from "react";
import { useLink } from "@refinedev/core";
import { Steps, Card, Upload, message, Button, Tag, Alert } from "antd"
import type { UploadProps } from 'antd';
import { UploadOutlined, WhatsAppOutlined } from '@ant-design/icons';
import axios from "axios";
export type ProfileStatusProps = {
    statusStep: any;
    profileId?: any;
    registerType?: any;
    cpfCnpj?: any;
    asaasDocuments?: any;
    showLink?: boolean;
};
export const ProfileStatus: React.FC<ProfileStatusProps> = ({
    statusStep,
    profileId,
    registerType,
    cpfCnpj,
    asaasDocuments,
    showLink = false
}) => {

    console.log({statusStep,profileId,asaasDocuments});

    const Link = useLink();
    const shareLink = `${PORTAL_URL}/register/${registerType}/${localStorage.getItem("companyHash")}?cpfCnpj=${cpfCnpj}`;

    const getDocStatus = (docStatus : string) => {
        let status,color;
        switch (docStatus) {
            case "NOT_SENT":
                status = "ENVIAR DOCUMENTO";
                color = "red";
                break;
            case "PENDING":
                status = "EM ANÁLISE";
                color = "yellow";
                break;                
            case "APPROVED":
                status = "DOCUMENTO APROVADO";
                color = "green";
                break;   
            case "REJECTED":
                status = "DOCUMENTO INVÁLIDO > TENTAR NOVAMENTE";
                color = "red";
                break;                                                
        
        }
        return <Tag color={color}>{status}</Tag>;
    }    

    const getCleanDescription = (description: string) => {

        return description?.includes("onboarding") ? "Para enviar esse documento, clique no link abaixo." : description;
    }

    const UploadFile = (profileId: any,documentId: any,documentType: any) => {
       
        // Method 1
        const uploadProps: UploadProps = {
            name: 'file',
            maxCount: 1,
            // accept: ".pdf",
            action: `${API_URL}/public/upload64`,
            headers: {
            },
            data: {
                profileId,
                documentId,
                documentType
            },
            // beforeUpload: (file: any) => {
            //     console.log(file);
            // },
            beforeUpload: async(file: any) => {
                return new Promise(async (resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    console.log(file);
                    reader.onload = () => {
                      // Attach the base64 string to the file object
                      // @ts-ignore              
                        //   file.base64String = reader?.result //?.replace(/^.*,/, '');
                        const base64String = reader.result as string;

                        // Add the name parameter to the data URL
                        const dataWithFileName = base64String.replace(
                            /^data:(.*);base64,/,
                            `data:$1;name=${encodeURIComponent(file.name)};base64,`
                        );
                        console.log(dataWithFileName);
            
                        // Attach the modified base64 string to the file object
                        file.base64String = dataWithFileName;
                      resolve(file);
                    };
              
                    reader.onerror = (error) => {
                      console.error('Error converting file to base64:', error);
                      reject(error);
                    };
                });            
            },       
            customRequest: async ({ onSuccess, onError, file } : any) => {
                try {
                console.log(file);
                // @ts-ignore
                  const response = await fetch(`${API_URL}/public/upload64`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json', // Specify JSON content type
                    },
                    body: JSON.stringify({
                        file,
                        profileId,
                        documentId,
                        documentType
                    }), // Include base64 string in the payload
                  });
            
                  const data = await response.json();
                  console.log(data);
    
                  if(data?.success){
                    alert("Arquivo submetido com sucesso!")
                    window.location.href = window.location.href;
                  }              
    
                } catch (error) {
                    alert("Oopps! Houve um erro no upload do arquivo. Tente novamente ou entre em contato com nosso suporte");
                    console.error('Error uploading file:', error);
                }
            }, 
            onChange(info : any) {
                                
                console.log("importt");
                console.log(info);
    
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    console.log(`${info.file.name} file uploaded successfully`);
                    alert("Arquivo submetido com sucesso!")
                    window.location.href = window.location.href;
                } else if (info.file.status === 'error') {
                    console.log(`${info.file.name} file upload failed.`);
                    alert("Oopps! Houve um erro no upload do arquivo. Tente novamente ou entre em contato com nosso suporte");
                }
            },
        };

        // Method 2
        const [fileList, setFileList] = useState([]);
        const onUploadChange = ({ fileList }: any) => {
            setFileList(fileList);
        };
        const customRequest = async ({ file, onSuccess, onError }: any) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('profileId', profileId);
            formData.append('documentId', documentId);
            formData.append('documentType', documentType);

            console.log(file);

            try {
                // Send the file and additional data to your backend
                const response = await axios.post(`${API_URL}/public/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                onSuccess(response.data);
                alert("Arquivo enviado com sucesso. Faça o refresh da página para atualizar o status");
                window.location.href = window.location.href;
            } catch (error) {
                onError(error);
                alert("Oops! Houve um erro")
            }
        };

        return (<>
        <Upload {...uploadProps}>
            <Button type={"primary"} icon={<UploadOutlined />}>Faça o upload do arquivo</Button>
        </Upload>
        {/* <Upload
            customRequest={customRequest}  // Use custom request to handle file upload
            fileList={fileList}
            onChange={onUploadChange}
            // listType="picture"
            maxCount={1}  // Limit to one file
        >
            <Button type={"primary"} icon={<UploadOutlined />}>Faça o upload do arquivo</Button>
        </Upload> */}
        </>);
    } 

    return (<>
        <Card style={{ marginTop: 20, borderWidth: 5, backgroundColor: "rgb(230, 244, 255)"}}>
            {(statusStep===1) && (<>
                <p style={{fontSize: 14}}><strong>Status:</strong> Cadastro aprovado com sucesso</p>
            </>)}
            {((!statusStep || statusStep===0) || (showLink && statusStep!==1)) && (<>
                <p style={{fontSize: 18}}>Envie dados e acompanhe os status deste cadastro através do link:</p>
                {/* <p style={{fontSize: 14}}>Dica: Aproveite e compartilhe este link com seu fornecedor</p> */}
                <Button type={"link"} style={{ borderColor: "#888", marginBottom: 15, background: "#fff"}} icon={<UploadOutlined />}>
                    <a href={shareLink} target="_blank">{shareLink}</a>
                </Button>
            </>)}
            {(statusStep===3) && (<>
                <p style={{fontSize: 14}}><strong>Status:</strong> Os seus dados estão em análise, em breve seu status será atualizado.</p>
                <p style={{fontSize: 14}}>Em seguida, você terá que anexar uma selfie e documentos extras.</p>
            </>)}
            {([4,6].includes(statusStep)) && (<>
                <p style={{fontSize: 14}}><strong>Status:</strong> Faça o upload de arquivos para finalizar o seu credenciamento.</p>
                {asaasDocuments?.data?.map((doc: any) => {
                    return (
                    <Card
                        title={`${doc.title} (${doc.responsible?.name || ""})`}
                        // extra={getDocStatus(doc.status)}
                        key={`${doc.type}-${doc.id}`}
                        style={{marginTop: 10}}
                    >
                        {/* <p><strong>{doc.title} ({doc.responsible.name}):</strong></p> */}
                        <p>Status: {getDocStatus(doc.status)}</p>
                        <p>Descrição: {getCleanDescription(doc.description)}</p>
                        {(["NOT_SENT","REJECTED"].includes(doc.status)) && (<>
                            {(doc.onboardingUrl) ?
                            (<>
                                <Button type={"link"} icon={<UploadOutlined />}>
                                    <a href={doc.onboardingUrl} target="_blank">Clique aqui para enviar o arquivo</a>
                                </Button>
                                {(doc.type==="IDENTIFICATION") && (<>
                                    <br/><br/>
                                    <p>São aceitos os seguintes documentos:</p>
                                    <p>•⁠  ⁠RG emitido na maioridade;</p>
                                    <p>•⁠  ⁠CNH no prazo de validade (a CNH digital não é aceita);</p>
                                    <p>•⁠  ⁠DNI (Documento Nacional de Identidade);</p>
                                    <p>•⁠  ⁠RNE (Registro Nacional de Estrangeiros);</p>
                                    <p>•⁠  ⁠CTPS (Carteira de Trabalho);</p>
                                    <p>•⁠  ⁠Carteira Profissional (Exemplo: OAB/CRO/CRC/CREA).</p>
                                </>)}
                            </>) 
                            :
                            (<>
                                {UploadFile(profileId,doc.id,doc.type)}
                            </>)}
                        </>)}
                    </Card>
                    );
                })}
            </>)}
            {([5].includes(statusStep)) && (
                <p style={{fontSize: 14}}><strong>Status:</strong> Os documentos enviados estão em análise, em breve seu status será atualizado.</p>
            )}
            {/* {(statusStep!==1 && !showLink) && (<>
                <Alert
                    message={`Atenção: Esta conta deve estar com cadastro 'Aprovado' para poder receber pagamentos. Clique no link para mais informações.`}
                    type="warning"
                    showIcon
                />
            </>)} */}
        </Card>
        {(statusStep!==1) && (
        <Button icon={<WhatsAppOutlined/>} size={"large"} style={{ background: "#25d366", color: "#fff", margin: "30px auto"}}>
            <Link to={`https://api.whatsapp.com/send?text=Acompanhe+o+cadastro+por+aqui+${shareLink}`} target="_blank">
            Compartilhe esse link pelo WhatsApp
            </Link>
        </Button>
        )}
    </>);
}